import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from './styles.css';
import { FaEye } from 'react-icons/fa';
import logo from '../../logo/logo.png';
import { AiOutlineWhatsApp } from 'react-icons/ai';

const Login = () => {
  const [oab_card, setOabCard] = useState('');
  const [security_number, setSecurityNumber] = useState('');
  const [token, setToken] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showSecurityNumber, setShowSecurityNumber] = useState(false);

  const toggleSecurityNumberVisibility = () => {
    setShowSecurityNumber(!showSecurityNumber);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://server.oabdf.org.br/login', {
        oab_card,
        security_number,
      });

      setToken(response.data.token);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('name', response.data.user.name);
      localStorage.setItem('oab_card', response.data.user.oab_card);
      localStorage.setItem('telephone', response.data.user.telephone);
      localStorage.setItem('email', response.data.user.email);

      setSuccess('Login realizado com sucesso.');

      await axios.post('https://server.oabdf.org.br/logs-login', {
        logMessage: `Login bem-sucedido para o usuário nº OAB ${oab_card}`,
      });

      setOabCard('');
      setSecurityNumber('');
      setTimeout(() => {
        window.location.href = '/home';
      }, 1000);
    } catch (error) {
      setError(
        'Erro ao fazer login. Verifique suas credenciais e tente novamente.',
      );

      await axios.post('https://server.oabdf.org.br/logs-login', {
        logMessage: `Falha no login para o usuário nº OAB ${oab_card}`,
      });

      console.error(error);
    }
  };

  return (
    <div className="login-form">
      <div className="login-header">
        <img src={logo} /> <h2>Login</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="oab_card">Carteira OAB:</label>
          <input
            type="text"
            id="oab_card"
            placeholder="Insira sua Carteira OAB"
            required
            value={oab_card}
            onChange={(e) => setOabCard(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="security_number">Número de Segurança:</label>
          <div className="password-input-container">
            <input
              type={showSecurityNumber ? 'text' : 'password'}
              id="security_number"
              placeholder="Insira seu Número de Segurança"
              required
              value={security_number}
              onChange={(e) => setSecurityNumber(e.target.value)}
            />
            <FaEye
              className="password-toggle-icon"
              onClick={toggleSecurityNumberVisibility}
            />
          </div>
        </div>
        <p style={{ color: '#666' }}>
          Desconsidere os zeros à esquerda na hora de preencher os campos.
        </p>
        <button type="submit">Login</button>
        <p>
          Não possui as suas credenciais? <br /> Entre em Contato{' '}
          <a
            style={{ color: '#25d366' }}
            href="https://api.whatsapp.com/send?phone=556130367000&text=Falar%20com%20atendente"
            target="_blank"
          >
            <AiOutlineWhatsApp size={24} />
          </a>
        </p>
      </form>
      {success && <p style={{ color: 'white' }}>{success}</p>}
      {error && (
        <>
          <p style={{ color: 'red' }}>{error}</p>
          <p style={{ color: 'white' }}>
            Em caso de problemas{' '}
            <a
              style={{ color: '#25d366' }}
              href="https://api.whatsapp.com/send?phone=556130367000&text=Falar%20com%20atendente"
              target="_blank"
            >
              Entre em contato
            </a>
          </p>
        </>
      )}
    </div>
  );
};

export default Login;
