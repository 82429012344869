import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VideoRecorder from '../VideoRecorder/VideoRecorder';
import ReportModal from '../ReportModal/ReportModal';
import AddToHomeScreenButton from '../AddToHomeScreenButton/AddToHomeScreenButton';
import styles from './styles.css';
import UserInfo from '../UserInfo/UserInfo';

const Home = () => {
  const [userLocation, setUserLocation] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationAccepted, setConfirmationAccepted] = useState(false);
  const [showReportModal, setshowReportModal] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('oab_card');
    localStorage.removeItem('telephone');
    navigate('/');
  };
  const sendToDatabase = async (data) => {
    try {
      const response = await fetch('https://server.oabdf.org.br/emergency', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.status === 200) {
        console.log('Dados enviados com sucesso para a base de dados:', data);
      } else {
        console.error(
          'Erro ao enviar dados para a base de dados:',
          response.statusText,
        );
      }
    } catch (error) {
      console.error('Erro ao enviar dados para a base de dados:', error);
    }
  };

  const collectUserLocationAndInfo = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        const local = `https://www.google.com/maps?q=${latitude},${longitude}`;

        const name = localStorage.name;
        const oab_card = localStorage.oab_card;
        const telephone = localStorage.telephone;
        const email = localStorage.email;

        const currentDate = new Date();
        const date = currentDate.toLocaleDateString('en-CA');
        const options = { hour: 'numeric', minute: 'numeric' };
        const hour = currentDate.toLocaleTimeString(undefined, options);

        const userLocationAndInfo = {
          name,
          oab_card,
          date,
          hour,
          local,
          telephone,
          email,
        };

        sendToDatabase(userLocationAndInfo);

        setConfirmationAccepted(true);
      });
    }
  };
  if ('Notification' in window) {
    if (Notification.permission === 'granted') {
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission()
        .then(function (permission) {
          if (permission === 'granted') {
            // Permissão concedida, você pode enviar notificações
          }
        })
        .catch(function (error) {
          console.error(
            'Erro ao solicitar permissão para notificações:',
            error,
          );
        });
    }
  }

  // Função para enviar uma notificação
  function sendNotification() {
    if ('Notification' in window && Notification.permission === 'granted') {
      const notification = new Notification('Título da Notificação', {
        body: 'Corpo da Notificação',
        icon: 'icone.png', // Substitua com o URL do ícone desejado
      });

      notification.addEventListener('click', function () {
        // Lidar com o clique na notificação (redirecionar para uma página, etc.)
      });
    }
  }

  const showConfirmationMessage = () => {
    setShowConfirmation(true);
  };

  return (
    <div className="home">
      {localStorage.name && localStorage.oab_card && <UserInfo />}
      <div className="home-bg">
        <div className="button-list">
          <button
            className="button btn-color"
            onClick={showConfirmationMessage}
          >
            Intervenção Emergencial
          </button>
          {showConfirmation && !confirmationAccepted && (
            <div className="modal-overlay">
              <div className="modal">
                <h3>Intervenção Emergencial</h3>
                <p>
                  Atenção: esta opção somente deve ser utilizada em casos
                  urgentes de violação das prerrogativas, a saber, prisão de
                  advogado no exercício profissional, busca e apreensão em
                  escritório, livre acesso à autoridade, acesso ao cliente
                  preso, impedimento em participar de audiência ou reunião. Seu
                  acesso poderá implicar em deslocamento de representante de
                  OAB.
                </p>
                <div className="modal-buttons">
                  <button
                    className="button"
                    onClick={() => setShowConfirmation(false)}
                  >
                    Cancelar
                  </button>
                  <button
                    className="button btn-color"
                    onClick={collectUserLocationAndInfo}
                  >
                    <a href="tel:61998048888">Concordar</a>
                  </button>
                </div>
              </div>
            </div>
          )}
          {userLocation && (
            <div>
              <p>Localização do usuário:</p>
              <a href={userLocation} target="_blank" rel="noopener noreferrer">
                Abrir no Google Maps
              </a>
            </div>
          )}
          <button className="button" onClick={() => setshowReportModal(true)}>
            Registre sua Denúncia
          </button>
          {showReportModal && (
            <ReportModal
              show={showReportModal}
              onClose={() => setshowReportModal(false)}
            />
          )}

          <VideoRecorder />
          <AddToHomeScreenButton />
          <button className="logout-button button" onClick={handleLogout}>
            Sair
          </button>
        </div>
      </div>
      <div className="home-header">
        <h2>
          <a
            href="https://oabdf.org.br/prerrogativas"
            target="blank_"
            style={{ color: '#FFF', textDecoration: 'none' }}
          >
            e-Prerrogativas
          </a>
        </h2>
        <h2>
          <a
            href="https://oabdf.org.br/cartilhas-oab/#flipbook-df_431971/1/"
            target="blank_"
            style={{ color: '#FFF', textDecoration: 'none' }}
          >
            Cartilha
          </a>
        </h2>
        <p style={{ color: 'white' }}>OAB/DF</p>
      </div>
    </div>
  );
};

export default Home;
